/* You can add global styles to this file, and also import other style files */
@import "semantic-ui-css/semantic.css";

.ui.padded.container {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.ui.primary.button {
  background-color: #9a0c46
}

.ui.primary.button:focus {
  background-color: #9a0c46
}

.ui.primary.button:hover {
  background-color: #c7105c
}

.ui.secondary.button {
  background-color: #575757
}

.ui.secondary.button:hover {
  background-color: #9d9d9d
}

.ui.button {
  background-color: #9a0c46
}

.ui.button:hover {
  background-color: #c7105c
}

.ui.inverted.menu {
  background-color: #575757
}

.link {
  color: #9a0c46
}

.link:hover {
  color: #c7105c
}

a:link {
  color: #9a0c46
}

a:visited {
  color: #c7105c
}

body {
  overflow-x: auto;
}

button.default.text.item {
  color: rgba(115, 115, 115, 0.87) !important; /* from .default.text:focus */
  width: 100%;
  background-color: unset;
}
